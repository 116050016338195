import React, { useEffect, useState } from 'react'
import { urlFor, client } from '../../client';


const Portfolio = () => {

    const [portfolio, setPortfolio] = useState([]);

    useEffect(() => {
        const query = '*[_type == "portfolio"]';

        client.fetch(query).then((data) => {
            setPortfolio(data);
            console.log(data[0].imageurl);
        });
    }, []);


  return (
        <div className='portfolio_container'>
            <div className='top-name center'>My Portfolio</div>
            <div className='img_container'>
           
                {portfolio.map((portfolio, index) => (

                    <div>
                        <div className='img_card'>
                            <div className='img_box'>
                                <img src={urlFor(portfolio.imageurl)} />
                            </div>
                            <div className='img_content'>
                                <h2>{portfolio.portfolio}</h2>
                            </div>
                        </div>
                    </div> 

                ))}

            </div>
           
        </div>
  )
}

export default Portfolio

{/* <img src={urlFor(portfolio.imageurl)} /> */}



{/* <div className='img_container'>
    <div className='img_card'>
        <div className='img_box'></div>
            <div img_content></div>
    </div>
</div> */}


















import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import { client } from '../../client'
import PortableText from "react-portable-text"
import BlockContent from "@sanity/block-content-to-react"



const Blog = () => {

    const [blogs, setBlogs] = useState([])


    useEffect(() => {
        const query = '*[_type == "blog"] | order(publishedAt desc)'
        
        client.fetch(query)
        .then((data) => {
            // console.log('before',data[0])
            setBlogs(data);
            
            const text = (data[0])
            console.log(text)
        })
    }, [])
    


  return (
    <div>
          <div style={{marginTop: "3rem"}} className='center top-name'>
            Learning Artefacts
          </div>

        <div class="blog_post">
            
            {blogs.map((blog, index) => (
                
                <div key={index} class="container_copy2">                        
                <Link
                    style={{textDecoration: "none"}}
                    to={`/blog/${blog.slug.current}`}>

 
                        <div>
                            <u><h1 class="blog-title">{blog.title}</h1></u>
                            <p class="blog-content">


                                <p className='content-contain img_limit'>
                                    <BlockContent
                                    blocks={blog.content}
                                    projectId= {process.env.REACT_APP_SANITY_PROJECT_ID}
                                    dataset="production"
                                    />
                                </p>

                                {/* {blog.content.length > 20 &&
                                <span class="blog-content">
                                ....
                                </span>
                                } */}
                            </p>
                        </div>
                        
                </Link>
                <hr style={{color: "white", width: "500px", opacity: "0.2", marginTop: "2rem", marginBottom: "0px"}} />
                    </div>
            ))}
            
        </div>

    </div>
  )
}

export default Blog


// {blog.content.children.substring(0, 200)}



{/* 
                                <PortableText
                                content={blog.content.slice(0, 10)}
                                projectId = {process.env.REACT_APP_SANITY_PROJECT_ID}
                                dataset = "production"
                        
                                serializers={{
                                    h1: (props) => <h1 style={{ color: "red" }} {...props} />,
                                    li: ({ children }) => <li className="special-list-item">{children}</li>,
                                    
                                }}
                                /> */}
import React, { useEffect, useState } from 'react'
import { client } from '../../client'
import BlockContent from "@sanity/block-content-to-react"



const About = () => {
    const [about, setAbout] = useState([])


    useEffect(() => {
        const query = '*[_type == "about"]'
        
        client.fetch(query)
        .then((data) => {
            setAbout(data);
            console.log(data)
        })
    }, [])

  return (
    <div>
        <h1 className='about-me'>About Me</h1>

        {about.map((about, index) => (
                <div className='about-container' key={index}>
                    
                    <BlockContent
                      blocks={about ? about.about  : ""}
                      projectId= {process.env.REACT_APP_SANITY_PROJECT_ID}
                      dataset="production"
                    />   

                </div>
            ))}
    </div>
  )
}

export default About
import React, { useEffect, useState } from 'react'
import { client } from '../../client'
import BlockContent from "@sanity/block-content-to-react"

import './Home.css'
import email from '../../assets/email.svg'
import linkedin from '../../assets/linkedin.svg'
import twitter from '../../assets/twitter.svg'


const Home = () => {
    
    
    const [home, setHome] = useState([])
    
    
    useEffect(() => {
        const query = '*[_type == "home"]'
        
        client.fetch(query)
        .then((data) => {
            setHome(data);
            console.log(data);
        })
    }, [])
    
    
    return (
    
    <div>

        <div class="center"> 
            <p class="top-name">
                Noman Naseer 
                <span className='top-name2'> Zargar</span> 
            </p> 

            <div class="main-para" >

                <p className="blog-content">

                    <BlockContent
                        blocks={home[0] ? home[0].home  : ""}
                        projectId= {process.env.REACT_APP_SANITY_PROJECT_ID}
                        dataset="production"
                    /> 
                </p>
            </div>


            {/* <div class="foot">
                <img style="width: 2rem; margin-right: 1rem;" src="email.svg" />
                <img style="width: 2rem; margin-right: 1rem;" src="linkedin.svg" />
            </div>  */}


            <div class="nav-wrapper-foot">
                <div class="logo-foot img_test"> 
                    <a href="mailto:contact@noman.one"><img style={{marginRight: "1rem"}}  class="logo_2" src={email} alt="Logo" /></a>
                    <a target="_blank" href="https://www.linkedin.com/in/nmnz"><img style={{marginRight: "1rem"}} class="logo_2" src={linkedin} alt="Logo" /></a>
                    {/* <a href="google.com"><img style={{marginRight: "1rem"}} class="logo_2" src={twitter} alt="Logo" /></a> */}
                </div>
            </div>
        </div> 
        
    </div>
  )
}

export default Home
import React, { useEffect, useState } from 'react'
import { client } from '../../client'



function Contact() {

    const [contacts, setContacts] = useState([])


    useEffect(() => {

        const query = '*[_type == "contact"] | order(value asc)'
        // const query = '*[_type == "contact"] | order(source desc)'
        
        client.fetch(query)
        .then((data) => {
            setContacts(data);   
            console.log(data)         
        })
    }, [])


  return (
    <div className='center'>
        {contacts.map((contact, index) => (
            <div className='contact_me' key={index}>                        
                {contact.source}: <u><a className='contact_me_items' href={contact.url}>{contact.value}</a></u>
            </div>
        ))}
            
    </div>
  )
}

export default Contact
import React from "react";
import nlogowhitebg from '../../assets/nlogowhitebg.svg'
import ColorMode from "../ColorMode/ColorMode";




const NavBar = () => {
  return (
    <div>
      <header>
        <div class="nav-wrapper">
          <div class="logo-container">
            <a href="/">
              <img class="logo" src={nlogowhitebg} alt="Logo" />
            </a>
          <ColorMode />
            
          </div>
          <nav>
            <input class="hidden" type="checkbox" id="menuToggle" />
            <label class="menu-btn" for="menuToggle">
              <div class="menu"></div>
              <div class="menu"></div>
              <div class="menu"></div>
            </label>
            <div class="nav-container">
              
              <ul class="nav-tabs">
                <a href="/">
                    <li class="nav-tab">Home</li>
                </a>

                <a href="/about">
                    <li class="nav-tab">About Me</li>
                </a>

                <a href="/blog">
                    <li class="nav-tab">Artefacts</li>
                </a>

                <a href="/portfolio">
                    <li class="nav-tab">Portfolio</li>
                </a>

                <a href="/contact">
                    <li class="nav-tab">Contact Me</li>
                </a>

                <a href="/resume">
                    <li class="nav-tab">Resume</li>
                </a>

              </ul>
            </div>
          </nav>
          
        </div>
      </header>
    </div>
  );
};

export default NavBar;

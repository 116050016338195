import React, { useEffect, useState } from 'react'
import { client } from '../../client'
import { useParams } from 'react-router-dom'
import BlockContent from "@sanity/block-content-to-react"

 

const BlogDetail = () => {
    const [blog, setBlog] = useState([])
    const { slug } = useParams()

    console.log(client)

    useEffect(() => {
        const query = `*[slug.current == "${slug}"]`
        
        client.fetch(query)
        .then((data) => {
            console.log("datadetail", data)
            setBlog(data[0]);
        })
    }, [])
    




  return (
    <div>
        <div style={{marginBottom: "2rem"}} class="blog_post">

              <div class="container_copy">
                  
                  <h1 class="blog-title">{blog ? blog.title : "Error 404: POST NOT FOUND"}</h1>
                  {/* <p class="blog-content">{blog.content}</p> */}

                  <p className="blog-content img_limit">
                    <BlockContent
                      blocks={blog ? blog.content  : ""}
                      projectId= {process.env.REACT_APP_SANITY_PROJECT_ID}
                      dataset="production"
                      /> 
                  </p>

                  <h3 style={{marginTop: "2rem"}} class="blog-date">{blog ? blog.publishedAt  : ""}</h3>
              </div>
   
          
      </div>
    </div>
  )
}

export default BlogDetail
import React from 'react'
import Home from './container/Home/Home'
import Blog from './container/Blog/Blog'
import BlogDetail from './container/BlogDetail/BlogDetail'
import NavBar from './container/NavBar/NavBar'
import Contact from './container/Contact/Contact'
import About from './container/About/About'
import Resume from './container/Resume/Resume'
import Portfolio from './container/Portfolio/Portfolio'
import { BrowserRouter, Routes, Route } from 'react-router-dom'



const App = () => {
  return (
    <div>

      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogDetail />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/portfolio" element={<Portfolio />} />
        </Routes>
      </BrowserRouter>
      
    </div>
  )
}

export default App
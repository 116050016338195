import React from 'react'

const Resume = () => {
  return (
    <div className='resume_container'>
        <div className='center'>
            <p style={{marginBottom: "1rem"}} className='top-name'>NOMAN NASEER</p>
            <p><a className='def_link' href="mailto:contact@noman.one">contact@noman.one</a> | <a className='def_link' href='https://www.linkedin.com/in/nmnz'>linkedin.com/in/nmnz</a> | +61475673455 | Parkwood, QLD 4214</p>
        </div>

        <div>
            <div>
                <h2 className='resume-title'>Education</h2>
                
                <div className='resume-sub-content'>
                    <div>
                        <p className='sub-title'>Master of Civil Engineering / Master of Engineering Project Management</p>
                    </div>

                    <div>
                        <p className='right-sub-title'>2022 &ndash; 2023</p>
                    </div>
                </div>
                <p className='sub-title2'>Griffith University, Gold Coast, Australia</p>

                <p><span className='sub-title'>Activities:</span> Industry Mentorship Program 2023.</p>
                <p className='sub-title'>Engineering Projects:</p>


                <div>
                    <p>1. Design of Gold Coast Seawall at Palm Beach to protect against sea waves using MIKE Zero software</p>
                    <p>2. Design Portal frame for Flexure, Shear and deflection (horizontal & vertical) as per AS4100-1998 using Strand7 software.</p>
                    <p>3. Design and moment analysis of a four-storey block of units with balcony, RCC slab using AS3600 design guidelines.</p>
                    <p>4. Design of hot-rolled steel portal frame of an industrial warehouse in Launceston, Tasmania in accordance with Australian standards.</p>
                    <p>5. Project Report of ACCIONA’s MacIntyre Wind farm, detailing the Project deliverables, WBS, Stakeholder Analysis, Project Delivery System and Risk Matrix and Environmental Impact Assessment</p>
                    <p>6. Project Evaluation Report of the proposed Logan & Gold Coast faster rail between Kuraby and Beenleigh.</p>
                </div>
            </div>

            <div>
                <div className='resume-sub-content'>
                    <div>
                        <p className='sub-title'>Bachelor of Civil Engineering</p>
                    </div>

                    <div>
                        <p className='right-sub-title'>2015 &ndash; 2019</p>
                    </div>
                </div>
                <p className='sub-title2'>Amity University, Noida, India</p>

                <p><span className='sub-title'>Activities:</span> Industry Mentorship Program 2023.</p>

                <p className='sub-title'>Engineering Projects:</p>
                <div>
                    <p className='sub-title'>1. Analysis of external loads & Designing of earthquake-tolerant structures of a G+8 building</p>
                    <p>
                        Designed a G+8 building in earthquake zone-3 using STAAD.Pro, calculating load cases & load combinations, followed by 
                        redesigning & retrofitting it to G+12, changing it to earthquake zone-5 and make it serviceable. Calculated the cost that this 
                        retrofitting would endure for client.
                    </p>
                </div>

                <div>
                    <p className='sub-title'>2. Base-isolation for Seismic restraint system in high-rise buildings</p>
                    <p>
                        In collaboration with the company ‘Resistoflex’, used “Sliding Pendulum Isolator” with lead rubber bearing to create a “Seismic 
                        restraint System” and "Base Isolation Technology" to design India's first earthquake-isolated corporate building-on-bearings, built 
                        in collaboration with IIT-Delhi & erected on Pendulum isolators to de-couple the building from the ground movement. Presented 
                        the study at Amity University and gave my case for need of university to adapt new parts of campus to Base isolation technology
                    </p>
                </div>

            </div>


            <div>
                <h2 className='resume-title'>Experience</h2>
                <div className='resume-sub-content'>
                    <div>
                        <p className='sub-title'>Site Engineer at Zargar Constructions &ndash; Srinagar, India</p>
                    </div>

                    <div>
                        <p className='right-sub-title'>Sep 2019 &ndash; Sep 2021</p>
                    </div>
                </div>

                <p>
                    Managed the construction of ‘Government Degree College’ — valued at INR 120 Crore (AUD 21 million). The work included 
                    building masonry structures (hostel, admin office, library and student-employment office), pavements & water-treatment centre.
                    Role: Project supervision. Maintained project schedule. Managed construction crew. Assessed quality of sections. Enforced 
                    workplace safety. Assisted in preparation of Contract drawings and specifications; technical tender procurement documentation 
                    & WBS.
                </p>

                <div>
                    <p className='sub-title'>Key Skills:</p>
                    <p><span className='sub-title'>1. Problem-solving skills:</span> Devised an innovative solution for a complex drainage issue, within budget.</p>
                    <p><span className='sub-title'>2. Professional communication skills:</span> Presented the scheduled project plans to the representative of college’s board of directors (stakeholders), receiving positive feedback for clarity in conveying technical information.</p>
                    <p><span className='sub-title'>3. Time management skills</span> Met deadlines, & overcame unexpected challenges while working on multiple sites.</p>
                </div>

                <div>
                    <p className='sub-title'>Key Achievements:</p>
                    <p><span className='sub-title'>1. Streamlining:</span> : Implemented a leaner construction project management system, resulting in an 15% increase in monthly efficiency & reduction in completion time by 12 days.</p>
                    <p><span className='sub-title'>2. Cost Control:</span> Identified and addressed potential cost overruns. In 6 months saved up to INR 1.5 Crore (A$250,000) from the project cost for the client.</p>
                    <p><span className='sub-title'>3. Increased Safety Measures:</span>  Implemented revised safety protocols, reducing the number of on-site accidents by 40% (compared to the previous projects of comparable valuation), ensuring worker safety & saving several thousands of dollars in insurance claims.</p>
                </div>
            </div>


            <div>
                <h2 className='resume-title'>Internships</h2>
                <div>
                    <div className='resume-sub-content'>
                        <div>
                            <p className='sub-title'>Engineering intern at J&K Economic Reconstruction Agency &ndash; Srinagar, India</p>
                        </div>

                        <div>
                            <p className='right-sub-title'>May 2018 &ndash; July 2018</p>
                        </div>
                    </div>

                    <div>
                        <p>
                            Site intern at the construction of a 4-lane grade-separator (overpass) Expressway-Corridor with estimated cost of INR 370 crore 
                            (AUD 65 Million), that helped abate the congestion and disruption of traffic flow during busy hours.
                        </p>
                        <p><span className='sub-title'>Role:</span> : Performed soil tests at the site where flyover-ramps was to be constructed for erecting inclined soil wall. Managed 
                            installation of pile-cap over the group of deep piles, installation of pre-fabricated form-works at the ramp site. Carried out auger 
                            boring for the deep friction-piles and laying the grid casing to prevent soil collapse, followed by sonic logging test.
                        </p>
                        <p><span className='sub-title'>Key Achievements:</span> : Demonstrated effective collaboration with a team of engineers and construction workers. Responsible for 
                            stakeholder management, regular meetings and presentations demonstrating proposals and project delivery, where good 
                            communication skills were demonstrated.
                        </p>
                    </div>
                </div>

                <div>
                    <div className='resume-sub-content'>
                        <div>
                            <p className='sub-title'>Engineering intern at J&K Economic Reconstruction Agency &ndash; Srinagar, India</p>
                        </div>

                        <div>
                            <p className='right-sub-title'>May 2017 &ndash; July 2017</p>
                        </div>
                    </div>

                    <p><span className='sub-title'>Role:</span> Assisting the senior site engineer in undertaking site surveying for the construction of New Mahjoor Bridge & construction 
                        of Grade Separator at Radio Kashmir Crossing, Srinagar, India. Performed stability analysis for the overpass’ formation walls and 
                        foundation works, followed by compaction. Site inspection, general administrative works and project coordination.
                    </p>
                </div>

            </div>


            <div>
                <h2 className='resume-title'>Software Skills</h2>

                <div>
                    <p>1. Engineering:<span className='sub-title'> AutoCAD; STAAD.Pro & Strand7; Mike Zero</span></p>
                    <p>2. Construction Management:<span className='sub-title'> Microsoft Project; Microsoft Office Suite; SynchroPro; Bluebeam Revu</span></p>
                </div>
            </div>


            <div>
                <h2 className='resume-title'>Volunteering</h2>
                <p>1. March 2023: Volunteered for Cancer Council Queensland at Kalbar Sunflower Festival 2023 (Scenic Rim).</p>
                <p>2. November 2022: Volunteered during swimming contest during Pan Pacific Masters Games 2022.</p>
                <p>3. July 2022: Volunteered at the info booth to greet & check-in almost 8 thousand marathon & 10km race runners at Gold Coast Marathon 2022, demonstrating swift decision-making & problem solving skills.</p>

            </div>


            <div>
                <h2 className='resume-title'>Licenses and Certifications</h2>

                <p><span className='sub-title'>Memberships:</span>Engineers Australia (EA), Australasian Chapter of International Geosynthetics Society (ACIGS), Griffith University 
                Engineers Australia Student Society (GUEASS).</p>
                <p>Certified AutoCAD professional – CADDEX India</p>
                <p>Manual Driving license with no restrictions; White Card (CPCCWHS1001); First Aid Certificate (HLTAID011); WHS (CPPSEC2103)</p>
            </div>

            <div>
                <h2 className='resume-title'>Referees</h2>

                <p>1. Akhter Javeed (Managing Director – Zargar Constructions) Ph: +917006677157 Email: ajaveedzargar@gmail.com</p>
                <p>2. Aamer Manzoor, AECOM (Brisbane) Ph: +61404808486 Email: aamer.manzoor@aecom.com</p>
                <p>3. Professor Cheryl Desha, Griffith University Ph: +61737357526 Email: c.desha@griffith.edu.au</p>
            </div>
       
        </div>
    </div>
  )
}

export default Resume


   {/* <p className='sub-title same_line'>Master of Civil Engineering / Master of Engineering Project Management</p>
                <p className='right-sub-title same_line'>2022 &ndash; 2023</p>
                <p>Griffith University, Gold Coast, Australia</p> */}